import React, { useState, useEffect, useRef } from 'react'
import { Card, Col, Container, Row } from 'reactstrap';
import premium from '../../assets/img/body/Premium.png'
import { Link, useParams } from 'react-router-dom';
import "../../assets/css/controls.css"
import loadingImg from '../../assets/img/body/loading.gif'
import axios from 'axios';
import { getOS } from 'getOs';
import { getBrowser } from 'getBrowser';
import AdsComponent from "components/Ads/AdsComponent";
export default function Body() {
  const { id } = useParams();
  const [fav, setFav] = useState()
  const [datas, setDatas] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const detailRef = useRef()

  let renderEp
  if (isLoading === false) {
    renderEp = datas.data.res_data.episode.map(data => {
      if (data.premium != 1) {
        return (
          <Row className='mt-4 mb-4 ml-auto mr-auto align-items-center' style={{ width: '100%' }}>
            <Col lg='12'>
              {/* <Link className='not-A' to={`/vdo/${id}/ep/${data.ep_name}`} target="_blank"> */}
              {/* <Card className='bg-dark ep-card ep-card-premium' onClick={() => alert('กรุณาสมัครสมาชิก')}> */}
              <Card className='bg-dark ep-card ep-card-premium' onClick={() => epClick()}>
                <Row className='align-items-center'>
                  <Col sm='12' md='1' lg='1' className='text-center'><h2>{data.ep_name}</h2></Col>
                  <Col sm='12' md='3' lg='3'>
                    <img className='mt-3 mb-3' style={{ width: '100%' }} src={data.ep_img} alt={data.ep_id} />
                  </Col>
                  <Col sm='12' md='12' lg='6'>
                    <p className='text text-limit'>
                      {data.ep_detail}
                    </p>
                  </Col>
                  <Col sm='0' md='0' lg='1'>
                  </Col>
                  <Col sm='12' md='12' lg='1'
                    className='pb-2 text-center'
                  >{data?.ep_time} นาที</Col>
                </Row>
                <img className='premium-img' src={premium} alt='premium' />
              </Card>
              {/* </Link> */}
            </Col>
          </Row>
        )
      } else {
        return (
          <Row className='mt-4 mb-4 ml-auto mr-auto align-items-center' style={{ width: '100%' }}>
            <Col lg='12'>
              <Link className='not-A' to={`/vdo/${id}/ep/${data.ep_name}`}
              // target="_blank"
              >
                <Card className='bg-dark ep-card'>
                  <Row className='align-items-center'>
                    <Col sm='12' md='1' lg='1' className='text-center'><h2>{data.ep_name}</h2></Col>
                    <Col sm='12' md='3' lg='3'><img className='mt-3 mb-3' style={{ width: '100%' }} src={data.ep_img} alt={data.ep_id} /></Col>
                    <Col sm='12' md='12' lg='6'>
                      <p className='text text-limit'>
                        {data.ep_detail}
                      </p>
                    </Col>
                    <Col sm='0' md='0' lg='1'>
                    </Col>
                    <Col sm='12' md='12' lg='1'
                      className='pb-2 text-center'
                    >{data?.ep_time} นาที</Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          </Row>
        )
      }
    })
  }

  const epClick = () =>{
    alert('กรุณาสมัครสมาชิก หรือ กรอกข้อมูลให้ครบถ้วน')
    window.location.href = '/login'
}

  const submitFav = () => {
    if (window.localStorage.getItem('auth')) {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/toggle/userfav`, {
        vod_id: id,
        fav_type: 'serie'
      }, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('auth')}`
        },
      }).then((result) => {
        setFav(result.data.res_data.vod_fav)
      })
    } else {
      alert('จำเป็นต้องเข้าสู่ระบบก่อน')
    }
  }
  const clickPlay = () => {
    setIsLoading(true)
    if (localStorage.getItem('auth')) {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/series`, { device: getBrowser() }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`
        },
      })
        .then(result => {
          const watchContinue = result.data.res_data.vod.filter(data => data.playlist_type == 'continue')
          if (watchContinue[0]) {
            // console.log(watchContinue[0].item.filter(fil => fil.vod_id == id))
            const watchConUrl = watchContinue[0].item.filter(fil => fil.vod_id == id)
            if (!watchConUrl[0]) {
              window.location.href = `/vdo/${id}/ep/EP.1`
              // window.open(`/vdo/${id}/ep/EP.1`)
            } else {
              window.location.href = `/vdo/${id}/ep/EP.${watchConUrl[0].ep_num}?play=${watchConUrl[0].current.slice(0, -3)}`
              // window.open(`/vdo/${id}/ep/EP.${watchConUrl[0].ep_num}?play=${watchConUrl[0].current}`)
            }
            watchContinue[0].item.filter(fil => fil.vod_id == id).map(data => {
              // window.open(`/vdo/${id}/ep/EP.${data.ep_num}?play=${data?.current || 0}`)
            })
          } else {
            window.location.href = `/vdo/${id}/ep/EP.1`
          }
        })
    } else if (!localStorage.getItem('auth')) {
      window.location.href = `/vdo/${id}/ep/EP.1`
      // window.open(`/vdo/${id}/ep/EP.1`)
    }
  }
  useEffect(() => {
    if (localStorage.getItem('auth')) {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/serie/episode`, {
        vod_id: id,
        page: 'main'
      }, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('auth')}`
        },
      }).then(result => {
        setFav(result.data.res_data.favorite)
        setDatas(result)
        setIsLoading(false)
      })
    } else {
      axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest/episode`, {
        vod_id: id,
        page: 'guest',
        device: getBrowser()
      }).then(result => {
        setFav(result.data.res_data.favorite)
        setDatas(result)
        setIsLoading(false)
      })
    }
  }, [])

  function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  function readmoreClick(el, readmoreBtn) {
    if (el.getAttribute('readmore') === 'true') {
      el.setAttribute('readmore', 'false')
      readmoreBtn.innerHTML = 'ย่อ'
    } else {
      el.setAttribute('readmore', 'true')
      readmoreBtn.innerHTML = 'อ่านต่อ'
    }
  }
  useEffect(() => {
    let el = document.getElementById('readme')
    var divHeight = el?.offsetHeight
    let lineHeight = parseInt(el?.style?.lineHeight)
    let line = divHeight / lineHeight
    if (line >= 5) {
      el.setAttribute('readmore', 'true')
      const readmoreBtn = document.createElement('button')
      readmoreBtn.innerHTML = 'อ่านต่อ'
      readmoreBtn.classList.add('readmoreBtn')
      readmoreBtn.addEventListener('click', () => readmoreClick(el, readmoreBtn))
      insertAfter(el, readmoreBtn)
    }
  }, [isLoading])

  if (isLoading === true) {
    return (
      <>
        <div className='bg-loading'>
          <img src={loadingImg} className='loading-img' alt='loading' />
        </div>
      </>
    )
  } else {
    const renderTags = datas.data.res_data.tagid.map((data) => {
      return (
        <span>{data + ' '}</span>
      )
    })
    return (
      <>
        <img className='banner-img' src={datas.data.res_data.imgX2} alt={datas.data.res_data.ch_name} />
        <div style={{textAlign:"center"}}>
        <AdsComponent dataAdSlot='5427278033' />
        </div>
        <div className="bg-blank">
          <Container className='pl-5 pr-5'>
            <Row className='mt-4'>
              <Col xs='12'>
                <h1 className='moblie-center'>{datas.data.res_data.name}</h1>
              </Col>
            </Row>
            <Row>
              <Col sm='12' md='7' lg='7'>
                <h2>{datas.data.res_data.short_detail}</h2>
              </Col>
              <Col sm='0' md='1' lg='1'></Col>
              <Col sm='12' md='4' lg='4'>
                <Row className='line-bottom text-center ml-1 mr-1'>
                  <Col>
                    <span>
                      {fav == 1 &&
                        <Link>
                          <i className='fa fa-heart fa-lg mb-3 mr-3 ml-3 not-A'
                            style={{ color: 'red' }}
                            onClick={submitFav}
                          ></i>
                        </Link>
                      }
                      {fav == 0 &&
                        <Link>
                          <i className='fa fa-heart fa-lg mb-3 mr-3 ml-3 not-A'
                            onClick={submitFav}
                          ></i>
                        </Link>
                      }
                    </span>
                    <span>
                      <i className='fa fa-share fa-lg mb-3 mr-3 ml-3' style={{ color: 'gray' }}></i>
                    </span>
                    <span>
                      <i className='fa fa-download fa-lg mb-3 mr-3 ml-3' style={{ color: 'gray' }}></i>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <button className='pl-4 pr-4 mt-4 play-btn-mb align-items-center' style={{ border: '1px solid white', backgroundColor: 'transparent', display: 'block', margin: 'auto' }}
                    onClick={() => clickPlay()}
                  >
                    <i class="fa fa-play"></i>
                    Play
                  </button>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm='12' md='7' lg='7'>
                <Row className='align-items-center moblie-center'>
                  <Col className='mb-5'>
                    <span className='mt-1 mb-1 pr-2 line-right text'
                      sm='12' md='4' lg='4'
                    >
                      ปี {datas.data.res_data.year}
                    </span>
                    <span className='ml-2 mt-1 mb-1 pr-2 line-right text'
                      sm='12' md='4' lg='4'
                    >
                      {datas.data.res_data.season}
                    </span>
                    <span className='ml-2 mt-1 mb-1 text'
                      sm='12' md='4' lg='4'
                    >
                      {renderTags}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm='12' md='7' lg='7'>
                <div id='readme' className='readme' ref={detailRef} style={{ 'lineHeight': '24px' }}>
                  {datas.data.res_data.detail}
                </div>
              </Col>
              <Col sm='0' md='1' lg='1' />
              <Col sm='12' md='4' lg='4'>
                <div>
                  <span className='text text-yellow'>นักแสดงนำ:</span>
                  <span className='ml-1 text'>
                    {datas.data.res_data.star}
                  </span>
                </div>
                <div>
                  <span className='text text-yellow'>ผู้กำกับ:</span>
                  <span className='ml-1 text'>
                    {datas.data.res_data.director}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col>
                <h2 className='text-yellow'>Episodes</h2>
              </Col>
            </Row>
            {renderEp}
          </Container>
        </div>
      </>
    )
  }

}
