import React from 'react'
import '../../assets/css/login.css'
import imgA from '../../assets/img/body/live-tv.png'

export default function Body(props) {
    const tvClick = (index, name, premium) => {
        if (!localStorage.getItem('auth')) {
            alert('กรุณาสมัครสมาชิก หรือ กรอกข้อมูลให้ครบถ้วน')
            window.location.href = '/login'
        } else {
            window.location.href = `/tv/${index}/${name}`
        }
    }
    
    const renderTV = props.datas.data.res_data.live.map((data, index) => {
        return (
            <>
                {data.premium == 0 &&
                    <img className='subbanner-img tv-subbanner silhouette pt-1 pb-1 pl-1 pr-1' src={data.img} alt={data.ch_name} onClick={() => tvClick(index, data.ch_name, data.premium)} />
                }
                {data.premium == 1 &&
                    <img className='subbanner-img silhouette pt-1 pb-1 pl-1 pr-1' src={data.img} alt={data.ch_name} onClick={() => tvClick(index, data.ch_name, data.premium)} />
                }
            </>
        )
    })
    return (
        <><div className='bg-blank'>
            <img className='live-img' src={imgA} alt='live' />
            <div className='mr-4 ml-4'>
                <div className='grid-col pt-5 pb-5 ml-5 mr-5'>
                    {renderTV}
                </div>
            </div>
        </div>
        </>
    )
}
