import React, { useRef, useEffect, useState, Component } from 'react';
import ReactPlayer from 'react-player';

function M3u8Player({ m3u8Url }) {

    const [playerError, setPlayerError] = useState(null);
    const playerRef = useRef(null);
  
    const handleError = (error) => {
      console.error('Player error:', error);
      setPlayerError('An error occurred during playback. Please refesh page');
    };
  
    useEffect(() => {
      setPlayerError(null); // Clear previous errors when URL changes
    }, [m3u8Url]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', margin: '0 auto' }}> {/* Container for styling */}
      {m3u8Url ? (
        <>
        <ReactPlayer
            playing={true}
            url={m3u8Url}
            controls={true}
            width="100%"
            height="auto"
            onError={handleError} // Add error handling
        />
        {playerError && (
            <p style={{ color: 'red', marginTop: '10px' }}>{playerError}</p>
          )}
        </>
      ) : (
        <p>Source Error.</p>
      )}
    </div>
  );
}



export default M3u8Player;