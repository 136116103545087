import React from 'react'
// import { Link } from 'react-router-dom';
import '../../assets/css/login.css'

export default function Body(props) {
    const renderTophit = props.datas.data.res_data.top_hit.map(data => {
        return (
            <a href={`/vdo/${data.vod_id}`}>
                <img className='hit-img pt-2 pb-2' src={data.tophit_img} alt={data.vod_id} />
            </a>
        )
    })
    return (
        <>
            <div className='bg-blank'>
                {renderTophit}
            </div>
        </>
    )
}
