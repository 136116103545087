import React, { useState, useEffect } from "react";
import '../../assets/css/style.css'
// reactstrap components
// import {
// } from "reactstrap";

// core components
import FooterBlack from "../../components/Footers/FooterBlack";
// sections for this page
import SectionNavigation from "../index-sections/SectionNavigation.js";
import Body from "../coming-sections/Body";
import Header from "views/header-sections/Header";
import loadingImg from '../../assets/img/body/loading.gif'
import axios from "axios";
import { getOS } from "getOs";

function ComingPage() {
  const [datas, setDatas] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest-coming`, { device: 0 })
      .then(result => {
        setDatas(result)
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      {isLoading === true &&
        <div className='bg-loading'>
          <img src={loadingImg} className='loading-img' alt='loading' />
        </div>
      }
      {isLoading === false &&
        <div className="bg-blank">
          <SectionNavigation />
          <Header img={datas} />
          <Body datas={datas} />
          <FooterBlack />
        </div>
      }
    </>
  );
}

export default ComingPage;
