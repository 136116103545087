import React, { useRef, useEffect, useState, Component } from 'react';
import ReactPlayer from 'react-player';

function M3u8Player({ m3u8Url,linkads,uservip }) {

    const [playerError, setPlayerError] = useState(null);
    const playerRef = useRef(null);
    const adContainerRef = useRef(null);
    const adsManagerRef = useRef(null);
    const adsLoaderRef = useRef(null);
    var adTagUrl = linkads.current;
    //console.log(adTagUrl);
  
    const handleError = (error) => {
      console.error('Player error:', error);
      setPlayerError('An error occurred during playback. Please refesh page');
    };
  
    useEffect(() => {
      if (adTagUrl && playerRef.current && window.google && window.google.ima) {
        loadAds(adTagUrl);
      }

      return () => {
        if (adsManagerRef.current) {
          adsManagerRef.current.destroy();
        }
        if (adsLoaderRef.current) {
          adsLoaderRef.current.destroy();
        }
      };
    }, [adTagUrl]);
  
    const loadAds = (adTagUrl) => {

    console.log("=== ADS ===")
    console.log(uservip)
    //console.log(linkads.current)

    const videoContent = playerRef.current.getInternalPlayer();
    const adDisplayContainer = new window.google.ima.AdDisplayContainer(
      adContainerRef.current,
      videoContent
    );
    adDisplayContainer.initialize();

    const adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);
    adsLoaderRef.current = adsLoader;

    adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      (adsManagerLoadedEvent) => {
        const adsManager = adsManagerLoadedEvent.getAdsManager(videoContent);
        adsManagerRef.current = adsManager;

        const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
        adsRenderingSettings.restoreCustomPlaybackStateOnComplete = true;

        adsManager.addEventListener(
          window.google.ima.AdErrorEvent.Type.AD_ERROR,
          (adErrorEvent) => {
            console.error('IMA ad error:', adErrorEvent.getError());
            resumeVideo();
          }
        );

        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
          () => {
            pauseVideo();
          }
        );

        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
          () => {
            resumeVideo();
          }
        );

        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
          () => {
            if (adsManagerRef.current) {
              adsManagerRef.current.destroy();
              adsManagerRef.current = null;
            }
          }
        );

        adsManager.init(videoContent.clientWidth, videoContent.clientHeight, window.google.ima.ViewMode.NORMAL);
        adsManager.start();
      }
    );

    adsLoader.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      (adErrorEvent) => {
        console.error('IMA SDK error:', adErrorEvent.getError());
        resumeVideo();
      }
    );

    const adsRequest = new window.google.ima.AdsRequest();
    adsRequest.adTagUrl = adTagUrl;
    // adsRequest.linearAdSlotWidth = 640;
    // adsRequest.linearAdSlotHeight = 360;
    // adsRequest.nonLinearAdSlotWidth = 640;
    // adsRequest.nonLinearAdSlotHeight = 150;

    adsLoader.requestAds(adsRequest);
  };

  const pauseVideo = () => {
    if (playerRef.current) {
      console.log('pause')
      playerRef.current.getInternalPlayer().pause();
    }
  };

  const resumeVideo = () => {
    if (playerRef.current) {
      console.log('play')
      playerRef.current.getInternalPlayer().play();
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', margin: '0 auto' }}> {/* Container for styling */}
      {m3u8Url ? (
        <>
        <div ref={adContainerRef} style={{
				position: "relative",
				width: "100%",
				height: "100%",
        zIndex: "8",
        }}>
        </div>
        <ReactPlayer
            ref={playerRef}
            playing={true}
            url={m3u8Url}
            controls={true}
            width="100%"
            height="auto"
            onError={handleError} // Add error handling
        />
        {playerError && (
            <p style={{ color: 'red', marginTop: '10px' }}>{playerError}</p>
          )}
        </>
      ) : (
        <p>Source Error.</p>
      )}
    </div>
  );
}



export default M3u8Player;